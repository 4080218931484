/*
 * @Author: your name
 * @Date: 2021-06-28 14:03:36
 * @LastEditTime: 2023-06-27 09:45:21
 * @LastEditors: Pengyue Zhou 1291431328@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \csms-web\src\constant\apiUrl.js
 */

// api接口集合
const ApiOption = {
  loginIn: '/stu/mLogin',
  queryStudentCoursePage: '/student/course/queryStudentCourseHasWorkPage', // 获取课程列表
  loadCourseDetail: '/student/course/loadStudentCourse', // 加载课程详情
  loadChapterCourseDetail: 'student/course/loadStudentCourseChapter', // 加载章节课程详情
  getCourseSignin: '/student/course/getCourseSignin', // 获取学院是否进行报到
  addStudyTrack: '/student/course/addStudyTrack', // 上传学习进度
  listBanner: '/home/listBanner', // 首页查询Banner
  listJpkc: '/home/listJpkc', // 首页查询精品课程
  listArticlePxdt: '/home/listArticlePxdt', // 首页查询培训动态
  listPxbc: '/home/listPxbc', // 首页查询培训班次
  queryCoursePage: '/course/queryCoursePage', // 分页获取新人驿站、精品课程
  pxdtQueryPage: '/cms/content/queryPage', // 分页获取培训动态
  contentLoad: '/cms/content/load', // 加载培训动态详情
  queryStudentCertificateList: '/student/certificate/queryStudentCertificateList', // 获取证书
  loadTodayLiveCourse: '/student/course/loadTodayLiveCourse', // 加载当天的直播
  loadStudentExtend: '/student/loadStudentExtend', // 加载单个学员信息
  saveStudentExtend: 'student/saveStudentExtend', // 新增学员更多信息
  addStudentCourseTxreg: '/student/courseTxreg/addStudentCourseTxreg', // 扫码新增学员更多信息
  editPassword: '/student/editPassword', // 修改密码
  saveCourseSignin: 'student/course/saveCourseSignin', // 学员进行报道
  courseListWorks: '/student/course/listWorks', // 查询学员作业
  queryStudentSurveyPage: '/student/survey/queryStudentSurveyPage', // 分页查询学员调查问券
  loadStudentSurvey: '/student/survey/loadStudentSurvey', // 加载单个学员调查问券
  surveySubmit: '/student/survey/submit', // 修改学员调查问券
  queryStudentCourseWithExamPage: '/student/exam/queryStudentCourseWithExamPage', // 分页查询学员课程-考试
  addStudentWork: '/student/course/addStudentWork', // 提交作业
  saveLiveChapterSignin: '/student/course/saveLiveChapterSignin', // 场次签到
  saveLiveChapterSignout: '/student/course/saveLiveChapterSignout', // 场次签退
  queryCourseCatTree: '/course/cat/queryCourseCatTree', // 获取课程搜索导航
  loadStudentInfo: '/student/loadStudentInfo', // 获取学习班次和证书数量
  queryStudentLiveChapterList: '/student/liveChapter/queryStudentLiveChapterList', // 直播大厅
  loadStudentLiveChapter: '/student/liveChapter/loadStudentLiveChapter', // 跳转到直播间或者线下课程的播放页，获取相应信息
  contentQueryPage: '/cms/content/queryPage',
  getVideoPlayAuth: '/common/aliyun/vod/getVideoPlayAuth', // 获取上传地址和凭证
  getVideoPlayInfo: '/common/aliyun/vod/getVideoPlayInfo', // 获取上传地址和凭证
  listChapterStudyTrack: '/student/course/listChapterStudyTrack', // 查询学员某个课程的章节学习轨迹
  listChapters: '/student/course/listChapters', // 查询学员某个课程的章节
  // 考试接口
  examGetStuExam: '/student/exam/getStuExam', // 考试答案
  examQueryPage: '/student/exam/queryPage', // 分页查询考试记录
  examEntryExam: '/student/exam/entryExam', // 进入考试
  newexamSaveAnswer: '/student/exam/saveAnswer', // 保存答案
  examStudentExamAnalysis: '/student/exam/studentExamAnalysis', // 试题分析
  examSubmitExam: '/student/exam/submitExam', // 提交试卷
  updatePauseCnt: '/student/exam/updatePauseCnt', // 切屏次数统计
}

// 合并其他地方的api
const mergeApiOption = {...ApiOption}

export default mergeApiOption
